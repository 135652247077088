<template>
    <footer>
        <div class="footer-inner container">
            <div class="footer-right" v-if="footer">
                <dl v-for="(footer_item, index) in footer" :key="index">
                    <dt>{{ footer_item.title }}</dt>
                    <dd>
                        <router-link v-for="item in footer_item.items" :key="item.id" rel="noopener noreferrer nofollow" :to="'/post/' + item.id">{{ item.title }}</router-link>
                    </dd>
                </dl>  
            </div>
            <div class="footer-languages" v-if="langs.length > 0">
                <div v-if="footer && footer.length > 0">
                    <router-link v-for="item in footer[0].items" :key="item.id" rel="noopener noreferrer nofollow" :to="'/post/' + item.id">{{ item.title }}</router-link>
                </div>
                <div>
                    <a v-for="(lang, index) in langs" :key="index" @click="changeLang(lang)" :disabled="$i18n.locale === lang" href="javascript:;">
                        {{ getLocaleName(lang) }}
                    </a>
                </div>
            </div>
            <div>
                <div class="footer-logo">
                    <img src="/image/sitelogosm" :alt="sysconfig.name" />
                </div>
                <div>
                    <div>
                        {{ $t('home.footer_introduce') }}
                    </div>
                    <!-- &copy; 2023 {{ sysconfig.name }}
                    <br /> -->
                    {{ $t('home.footer_support_email') }}: {{ sysconfig.email }}
                </div>
                <div>
                    <!-- :href="getlinkList().twitter" -->
                    <a @click="jump('twitter')">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-twitter" />
                        </svg>
                    </a>
                    <a @click="jump('facebook')">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-facebook" />
                        </svg>
                    </a>
                    <a @click="jump('medium')">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-medium" />
                        </svg>
                    </a>
                    <a @click="jump('instagram')">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-instagram" />
                        </svg>
                    </a>
                    <a @click="jump('youtube')">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-youtube" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { getAppContext } from 'utilities/helper';
import '@/assets/svg/logo/icon-twitter.svg';
import '@/assets/svg/logo/icon-facebook.svg';
import '@/assets/svg/logo/icon-instagram.svg';
import '@/assets/svg/logo/icon-youtube.svg';
import '@/assets/svg/logo/icon-medium.svg';

export default {
    data() {
        return { 
            langs: [],
            context: null,
            linkList:{
                'en':{
                    twitter:"https://twitter.com/Crypto_BAORUI",
                    facebook:"https://www.facebook.com/BaoruiWorldwide",
                    medium:"https://medium.com/@Baorui"
                },
                'ms':{
                    twitter:"https://twitter.com/Baorui_Malaysia",
                    facebook:"https://www.facebook.com/BaoruiMY",
                    medium:"https://medium.com/@Baorui_Malaysia"
                },
                'zh':{
                    twitter:"https://twitter.com/Baorui_ZH",
                    facebook:"https://www.facebook.com/BaoruiZH",
                    medium:"https://medium.com/@Baorui"
                }
            }
        };
    },

    computed: {
        footer: function () {
            return this.context.homeConfig ? this.context.homeConfig.footer : null;
        }
    },

    created() {
        this.langs = Object.freeze(this.sysconfig.supportedLanguages);
        this.context = getAppContext();
    },

    methods: {
        jump:function(type){
            let routes= {
                'ko':{
                    'facebook':'https://www.facebook.com/DookvKorea/',
                    'youtube':'https://www.youtube.com/@Dookv_Korea',
                    'twitter':'https://twitter.com/Dookv_Korea',
                    'medium':'https://medium.com/@Dookv_Korea',
                    'instagram':'https://www.instagram.com/dookv_korea/'
                },
                'en':{
                    'facebook':'https://www.facebook.com/DookvWorld/',
                    'youtube':'https://www.youtube.com/@Dookv-',
                    'twitter':'https://twitter.com/Dookv_',
                    'medium':'https://medium.com/@Dookv',
                    'instagram':'https://www.instagram.com/dookv_/'
                }
            }
            let language =this.$i18n.locale;
            if(language ==='ko'){
                window.open(routes['ko'][type])
            }else{
                window.open(routes['en'][type])
            }
        },
        changeLang: function (lang) {
            $.changeLang(this, lang);
        },
        getlinkList(){
            const lang = this.$i18n.locale
            let language
            if(lang !== 'en' || lang !== 'ms' || lang !== 'zh'){
                language = 'en'
            }else {
                language = lang
            }
            return this.linkList[language]
        }
    }
};
</script>
<style scoped>
.ft-icon {
    fill: #000;
    stroke: #fff;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: .5rem;
}

@media (max-width: 1024px) {
    .footer-inner {
        flex-direction: column;
    }

    .footer-inner>div {
        margin: 1rem 0;
    }
}
</style>